import React, { Component } from "react";
import { Fade, Slide } from "react-reveal";
import emailjs from 'emailjs-com';

class Contact extends Component {
  handleSubmit(event) {
    event.preventDefault();
  
    emailjs.init('uDKjwgntz8L4J2NsI');
  
    const { contactName, contactEmail, contactSubject, contactMessage } = event.target.elements;
  
    const templateParams = {
      from_name: contactName.value,
      from_email: contactEmail.value,
      to_name: 'arturojorgecapillablas@gmail.com',
      subject: contactSubject.value,
      message_html: contactMessage.value,
    };
  
    emailjs.send('service_3ni0a3n', 'template_bvpvdbk', templateParams, 'uDKjwgntz8L4J2NsI')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        //alert('Message sent successfully!');
      }, (error) => {
        console.log('FAILED...', error);
        alert('Message failed to send.');
      });
  }

  render() {
    if (!this.props.data) return null;

    const name = this.props.data.name;
    const description = this.props.data.description;
    const street = this.props.data.address.street;
    const city = this.props.data.address.city;
    const state = this.props.data.address.state;
    const zip = this.props.data.address.zip;
    const phone = this.props.data.phone;
    const phone2 = this.props.data.phone2;
    const message = this.props.data.contactmessage;

    

    return (
      <section id="contactanos">
        <Fade bottom duration={1000}>
          <div className="row section-head">
            <div className="two columns header-col">
              <h1>
                <span>Get In Touch.</span>
              </h1>
            </div>

            <div className="ten columns">
              <p className="lead">{message}</p>
            </div>
          </div>
        </Fade>

        <div className="row">
          <Slide left duration={1000}>
            <div className="eight columns">
              <form action="" method="post" id="contactForm" name="contactForm" autoComplete="off" onSubmit={this.handleSubmit}>
                <fieldset>
                  <div>
                    <label htmlFor="contactName">
                      Nombre Completo <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      defaultValue=""
                      size="35"
                      id="contactName"
                      name="contactName"
                      onChange={this.handleChange}
                      autoComplete="none"
                      required
                    />
                  </div>

                  <div>
                    <label htmlFor="contactEmail">
                      Correo Electrónico <span className="required">*</span>
                    </label>
                    <input
                      type="email"
                      defaultValue=""
                      size="35"
                      id="contactEmail"
                      name="contactEmail"
                      onChange={this.handleChange}
                      role="presentation"
                      autoComplete="none"
                      required
                    />
                  </div>

                  <div>
                    <label htmlFor="contactSubject">Asunto</label>
                    <input
                      type="text"
                      defaultValue=""
                      size="35"
                      id="contactSubject"
                      name="contactSubject"
                      onChange={this.handleChange}
                      autoComplete="none"
                    />
                  </div>

                  <div>
                    <label htmlFor="contactMessage">
                      Mensaje <span className="required">*</span>
                    </label>
                    <textarea
                      cols="50"
                      rows="15"
                      id="contactMessage"
                      name="contactMessage"
                      autoComplete="none"
                      style={{resize:'none'}}
                      required
                    ></textarea>
                  </div>

                  <div>
                    <button className="submit">ENVIAR</button>
                    <span id="image-loader">
                      <img alt="" src="images/loader.gif" />
                    </span>
                  </div>
                </fieldset>
              </form>

              <div id="message-warning"> Error boy</div>
              <div id="message-success">
                <i className="fa fa-check"></i>Your message was sent, thank you!
                <br />
              </div>
            </div>
          </Slide>

          <Slide right duration={1000}>
            <aside className="four columns footer-widgets">
              <div className="widget widget_contact">
                <h4>Dirección y Teléfonos</h4>
                <p className="address">
                  {name}
                  <br />
                  {description}
                  <hr />
                  {street}, {zip} <br />
                  {city}, {state}
                  <br />
                  <span>{phone}</span>
                  <br />
                  <span>{phone2}</span>
                </p>
              </div>

            </aside>
          </Slide>
        </div>
      </section>
    );
  }
}

export default Contact;
