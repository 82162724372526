import React, { Component } from "react";
import Slide from "react-reveal";

class Resume extends Component {
  getRandomColor() {
    let letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  render() {
    if (!this.props.data) return null;

    //Pavonado
    const pavonado = this.props.data.pavonado.map(function (pavonado){
      return (
        <div key={pavonado.info}>
          <p>{pavonado.descripcion}</p>
        </div>
      );
    });

    //temple y revenido
    const templeRevenido = this.props.data.templeRevenido.map(function (templeRevenido){
      return (
        <div key={templeRevenido.info}>
          <p>{templeRevenido.descripcion}</p>
        </div>
      );
    });

    //grabad laser
    const grabadoLaser = this.props.data.grabadoLaser.map(function (grabadoLaser){
      return (
        <div key={grabadoLaser.info}>
          <p>{grabadoLaser.descripcion}</p>
        </div>
      );
    });

    //demas servicios
    const demasServicios = this.props.data.demasServicios.map(function (demasServicios){
      return (
        <div key={demasServicios.info}>
          <ul>
            <li>{demasServicios.templeRegionalizado}</li>
            <li>{demasServicios.bonificado}</li>
            <li>{demasServicios.decapado}</li>
            <li>{demasServicios.cementacion}</li>
          </ul>
        </div>
      );
    });

    /*
    const education = this.props.data.education.map(function (education) {
      return (
        <div key={education.school}>
          <h3>{education.school}</h3>
          <p className="info">
            {education.degree} <span>&bull;</span>
            <em className="date">{education.graduated}</em>
          </p>
          <p>{education.description}</p>
        </div>
      );
    });
    
    const work = this.props.data.work.map(function (work) {
      return (
        <div key={work.company}>
          <h3>{work.company}</h3>
          <p className="info">
            {work.title}
            <span>&bull;</span> <em className="date">{work.years}</em>
          </p>
          <p>{work.description}</p>
        </div>
      );
    });
    */

    return (
      <section id="servicios">
        <Slide left duration={1300}>
          <div className="row pavonado">
            <div className="three columns header-col">
              <h1>
                <span>PAVONADO</span>
              </h1>
            </div>

            <div className="nine columns main-col">
              <div className="row item">
                <div className="twelve columns">{pavonado}</div>
              </div>
            </div>
          </div>
        </Slide>

        <Slide left duration={1300}>
          <div className="row templeRevenido">
            <div className="three columns header-col">
              <h1>
                <span>TEMPLE</span>
              </h1>
            </div>

            <div className="nine columns main-col">{templeRevenido}</div>
          </div>
        </Slide>
        
        <Slide left duration={1300}>
          <div className="row grabadoLaser">
            <div className="three columns header-col">
              <h1>
                <span>GRABADO LÁSER</span>
              </h1>
            </div>

            <div className="nine columns main-col">{grabadoLaser}</div>
          </div>
        </Slide>

        <Slide left duration={1300}>
          <div className="row demasServicios">
            <div className="three columns header-col">
              <h1>
                <span>Demás servicios</span>
              </h1>
            </div>

            <div className="nine columns main-col">{demasServicios}</div>
          </div>
        </Slide>
      </section>
    );
  }
}

export default Resume;
