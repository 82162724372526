import React, { Component } from "react";
import Fade from "react-reveal";

class About extends Component {
  render() {
    if (!this.props.data) return null;

    const profilepic = "images/" + this.props.data.image;
    const bio = this.props.data.bio;

    return (
      <section id="conocenos">
        <Fade duration={1000}>
          <div className="row">
            <div className="three columns">
              <img
                className="profile-pic"
                src={profilepic}
                alt="Águila Logo"
              />
            </div>
            <div className="nine columns main-col">
              <h2>QUIENES SOMOS</h2>

              <p>{bio}</p>
              <div className="row">
                <div className="columns contact-details">
                  <h2>UBÍCANOS</h2>

                  <div id="mapa" >
                    <iframe 
                      title="googleMaps"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.9627301777537!2d-98.1789768241342!3d19.109290882102187!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cfc35a62ebf2f9%3A0xf230b4742a1a5753!2sTratamientos%20T%C3%A9rmicos%20%C3%81guila!5e0!3m2!1ses-419!2smx!4v1690954119609!5m2!1ses-419!2smx"
                      allowFullScreen="" 
                      loading="lazy" 
                      referrerPolicy="no-referrer-when-downgrade" 
                    />
                  </div>

                </div>
              </div>
            </div>
          </div>
        </Fade>
      </section>
    );
  }
}

export default About;
