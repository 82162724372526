import { useEffect } from "react";

const CondicionesGenerales = () => {

    useEffect(() => {
        document.title = "Águila - Condiciones Generales"
    }, [])

    return(
        <>
            <nav id="nav-wrap">
                <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
                    Show navigation
                </a>
                <a className="mobile-btn" href="#home" title="Hide navigation">
                    Hide navigation
                </a>

                <ul id="nav" className="nav"> 
                    <li>
                        <a href="/#inicio">
                            Inicio
                        </a>
                    </li>

                    <li>
                        <a href="/#conocenos">
                            Quiénes somos
                        </a>
                    </li>

                    <li>
                        <a href="/#servicios">
                            Servicios
                        </a>
                    </li>

                    <li>
                        <a href="/#imagenes">
                            Imágenes
                        </a>
                    </li>

                    <li>
                        <a href="/#contactanos">
                            Contáctanos
                        </a>
                    </li>
                </ul>
            </nav>
            
            <div className="row" style={{marginTop: '70px'}}>
                <h1 style={{'text-align':'center', 'color': '#fdd159'}}> Condiciones Generales </h1>
                <ol style={{marginTop: '70px', 'color': 'white'}}>
                    <li>
                        El cliente debe asegurarse de que el tipo de acero sea el que nos indica en caso contrario cualquier tipo de reproceso deber ser avalado por el cliente considerando que es bajo su responsabilidad el riesgo del reproceso y tendrá costo extra.
                    </li>
                    <li>
                    	El cliente debe proporcionar la información necesaria para procesar su material.
                    </li>
                    <li>
                    	Los precios no incluyen IVA el cual se adiciona al momento de la factura.
                    </li>
                    <li>
                    	No nos hacemos responsables por piezas en acero equivocado o mal diseñado.
                    </li>
                    <li>
                    	No nos hacemos responsables por los materiales después de 45 días a partir de la fecha acordada de entrega.
                    </li>
                    <li>
                    	En trabajos de urgencia el costo del tratamiento se cotizará de acuerdo con el volumen del pedido y tiempo de entrega solicitado por el cliente.
                    </li>
                    <li>
                    	En tratamiento térmico temple al agua es responsabilidad del cliente el riesgo de deformación o fractura.
                    </li>
                    <li>
                    	Al firmar la orden de trabajo usted acepta las condiciones generales de nuestros servicios.
                    </li>
                </ol>                
            </div>            
        </>
    );
}  

export default CondicionesGenerales;