import React, { Component } from "react";
import ParticlesBg from "particles-bg";
import Fade from "react-reveal";
import imagen from "../Animacion/logoAguila.png";

class Header extends Component {
  render() {
    if (!this.props.data) return null;

    const name = this.props.data.name;
    const description = this.props.data.description;
    //const imagen = "./images/loader.gif";
    const whatsappMe = this.props.data.whatsappme;

    let config = {
      num: [0, 1],
      rps: 0.3,
      radius: [5, 20],
      life: [0.3, 2],
      v: [0.1, 0.5],
      tha: [0, 5],
      body: imagen, // Whether to render pictures
      rotate: [0, 5],
      alpha: [0.6, 0],
      scale: [1, 5],
      position: "all", // all or center or {x:1,y:1,width:100,height:100}
      cross: "cross", // cross or bround
      random: null,  // or null,
      g: -2,    // gravity
      f: [0, 0], // force
      
    };

    return (
      <header id="inicio">
        <ParticlesBg type="custom" bg={true} config = {config} />

        <nav id="nav-wrap">
          <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
            Show navigation
          </a>
          <a className="mobile-btn" href="#home" title="Hide navigation">
            Hide navigation
          </a>

          <ul id="nav" className="nav">
            <li className="current">
              <a className="smoothscroll" href="#inicio">
                Inicio
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#conocenos">
                Quiénes somos
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#servicios">
                Servicios
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#imagenes">
                Imágenes
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#contactanos">
                Contáctanos
              </a>
            </li>
          </ul>
        </nav>

        <div className="row banner">
          <div className="banner-text">
            <Fade bottom>
              <h1 className="responsive-headline">{name}</h1>
            </Fade>
            <Fade bottom duration={1200}>
              <h3>{description}.</h3>
            </Fade>
            <hr />
            <Fade bottom duration={2000}>
              <ul className="social">
                <a href={whatsappMe} className="button btn project-btn" id="whatsapp" target='_blank' rel='noreferrer' style={{margin: '20px'}}>
                  <i className="bi bi-whatsapp"></i>Envíanos un WhatsApp
                </a>
                <a href="CondicionesGenerales" className="button btn project-btn" id="condiciones" target='_blank' rel='noreferrer'>
                  <i className="bi bi-file-earmark-text"></i>Condiciones Generales
                </a>
              </ul>
            </Fade>
          </div>
        </div>

        <p className="scrolldown">
          <a className="smoothscroll" href="#conocenos">
            <i className="icon-down-circle"></i>
          </a>
        </p>
      </header>
    );
  }
}

export default Header;
